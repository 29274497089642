import axios from "axios";
import { API_URL } from "../helpers/Constant";

export const getData = async (api) => {
    try {
        var response = await axios({
            method: 'GET',
            url: API_URL + api
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const postData = async (api, data) => {
    try {
        var response = await axios({
            method: 'POST',
            url: API_URL + api,
            data: data
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error.response);
        return error.response;
    }
}
