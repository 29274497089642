import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postData } from '../api/api';
import { GETPAYMENTSUCCESS } from '../helpers/Constant';
import { ACCOUNT } from '../helpers/Constant';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { HiOutlineArrowRight } from 'react-icons/hi';

function PaymentSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(undefined);

  useEffect(() => {
    if (!location.search) {
      return;
    }

    async function fetchPaymentSuccess() {
      const urlParams = new URLSearchParams(location.search);
      const sessionId = urlParams.get('session_id');
      const userId = '64f81023b1b6cb87c0852b70';

      var data = {
        "session_id": sessionId,
        "userId": userId
      };

      if (sessionId) {
        try {
          const response = await postData(GETPAYMENTSUCCESS, data);

          setTimeout(() => {
            setIsLoading(false);
            setPaymentSuccess(response.success);
          }, 2000);
        } catch (error) {
          console.error('Error fetching payment success:', error);
          setIsLoading(false);
          setPaymentSuccess(false); 
        }
      } else {
        console.error('Session ID not found in the URL');
      }
    }

    fetchPaymentSuccess();
  }, [location.search]);

  return (
    <div className='wrapper h-100'>
      <div className="loader d-flex flex-column justify-content-center gap-4">
        {(isLoading) &&
          <>
            <Oval color="#daff00" secondaryColor="#ffffff" strokeWidth={3} strokeWidthSecondary={3} height={50} width={50} />
            <div className='text-center'>
              <h5 className='mb-1 text-warning'>Please wait ...</h5>
              <p className='mb-0 text-white opacity-75'>Your payment is under process.</p>
            </div>
          </>
        }
        {(paymentSuccess !== undefined && !isLoading) && 
          (paymentSuccess ? (
            <div className="d-flex flex-column align-items-center justify-content-center gap-4">
              <div className="success-icon">
                <div className="success-icon__tip"></div>
                <div className="success-icon__long"></div>
              </div>
              <h5 className='mb-0 text-warning text-center'>Amount successfully <br /> added to wallet.</h5>
              <button type='button' className='go_wallet btn text-white d-flex align-items-center p-0 gap-2' onClick={() => navigate(`/${ACCOUNT}`)}><span>Go to Wallet</span>< HiOutlineArrowRight size={16} /></button>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center gap-4">
              <div className="fail-icon">
                <div className="fail-icon__tip"></div>
                <div className="fail-icon__long"></div>
              </div>
              <h5 className='mb-0 text-red text-center'>Your payment request failed <br /> please try again.</h5>
              <button type='button' className='go_wallet btn text-white d-flex align-items-center p-0 gap-2' onClick={() => navigate(`/${ACCOUNT}`)}><span>Go to Wallet</span>< HiOutlineArrowRight size={16} /></button>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default PaymentSuccess;
