import React, { useEffect, useState } from 'react';
import { postData } from '../api/api';
import { GETPAYMENTDATA, GETACCOUNT, EXPRESSPAYMENT } from '../helpers/Constant';
import BackButton from '../common/backButton';
import { TRANSACTIONHISTORY } from '../helpers/Constant';
import { useNavigate } from 'react-router-dom';
import { FaWallet } from "react-icons/fa6";
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
const stripePromise = loadStripe("pk_test_51N13JmSDs2DhmUvL1HiGBAABs3DcyiGuWrGi9zdpZJv1qAZcdG06ogylMPKFKF9api2GYBuF99KeorwOnNRJa8WA00P7khLxvj");

function Account() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);
  const [accontBalance, setAccountBalnce] = useState("");
  const [amountToAdd, setAmountToAdd] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePaymentConfirmation = async (paymentMethodId) => {
    const response = await postData(EXPRESSPAYMENT, { paymentMethodId });

    console.log(response);
  };

  useEffect(() => {
    async function fetchAccount() {
        const userId = '64f81023b1b6cb87c0852b70';
        const account = await postData(GETACCOUNT, { userId });
        setAccountBalnce(account.account.balance);

        console.log('test');
        const initializeStripeElements = async () => {
            if(!stripe) return false;
            const expressCheckoutOptions = {
                buttonType: {
                    googlePay: 'buy',
                },
                wallets : {
                  applePay : 'never'
                }
            }
            const elements = stripe.elements({
                locale: 'de',
                mode: 'payment',
                amount: 1099,
                currency: 'usd'
            });

            const expressCheckoutElement = elements.create(
                'expressCheckout',
                expressCheckoutOptions
            )

            expressCheckoutElement.on('paymentMethod', async (event) => {
                const paymentMethodId = event.paymentMethod.id;
                await handlePaymentConfirmation(paymentMethodId);
            });

            
            expressCheckoutElement.mount('#express-checkout-element')
        
        };

        initializeStripeElements();
    }
    fetchAccount();
}, [stripe, elements]);

  const openAddMoneyModal = () => {
    setShowAddMoneyModal(true);
  };

  const closeAddMoneyModal = () => {
    setShowAddMoneyModal(false);
    setAmountToAdd("");
    setShowError(false);
  };

  const handleAmountChange = (event) => {
    setAmountToAdd(event.target.value);
    setShowError(false);
  };

  const handleAddMoney = async (event) => {
    console.log(amountToAdd)
    if (amountToAdd === '') {
      setShowError(true);
      setErrorMessage("Please enter an amount.");
      return;
    }
    if (amountToAdd <= 0) {
      setShowError(true);
      setErrorMessage("Please enter an amount greater than 0.");
      return;
    }

    const response = await postData(GETPAYMENTDATA, { amountToAdd });
    window.location.href = response;
  };

  const handleTransaction = () => {
    navigate(`/${TRANSACTIONHISTORY}`);
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

  };

  const expressCheckoutOptions = {
      buttonType: {
          googlePay: 'buy',
      },
      wallets : {
        applePay : 'never'
      }
  }


  return (
    <div className='wrapper h-100'>

      {(showAddMoneyModal) ? (
        <BackButton label='Wallet' className="position-relative" closeFunction={closeAddMoneyModal} />
      ) : (
        <div className='position-relative'>
          <BackButton label='Wallet' className="position-relative" />
          <button type='button' className='transaction_btn text-white bg-transparent h-100 position-absolute top-0 end-0 d-flex align-items-center justify-content-center border-0' onClick={handleTransaction}>
            <span className='fw-bold'>Transaction History</span>
          </button>
        </div>
      )}

      <div className='account_wrapper'>
        <div className='row justify-content-between balance_box bg-secondary gx-2 py-3 px-2 my-4 mx-0'>
          <div className='col-8 d-flex'>
            <FaWallet size={40} />
            <div className='ps-3'>
              <p className='mb-0 text-white opacity-50'>Balance</p>
              <h6 className='text-white mb-0 fw-bold'>₹{accontBalance}</h6>
            </div>
          </div>
          <div className='col-4 d-flex align-items-center'>
            <button type='button' className='cta_button mw-100 bg-warning d-flex align-items-center justify-content-center position-relative border-0' onClick={openAddMoneyModal}>
              <span className='fw-bold lh-1'>+ Add Money</span>
            </button>
          </div>
        </div>
      </div>
      {/* Add money modal */}
      <div className={`modal ${showAddMoneyModal ? 'show' : ''} ${showAddMoneyModal ? 'modal-slide-in' : 'modal-slide-out'}`} style={{ display: showAddMoneyModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal_dialog" role="document">
          <div className="modal-content modal_content p-3 account_modal position-absolute bottom-0">
            <div className="modal-body p-0">
              <h6 className="fw-bold mb-0 text-white opacity-75 mt-2 mb-4">Enter Money To Add</h6>
              <form className='profile_form'>
                <div className='row g-3'>
                  <div className='col-9'>
                      <input type="number" className='w-100 px-3' id="amount" name="amount" value={amountToAdd} onChange={handleAmountChange} placeholder='Amount' />
                  </div>
                  <div className='col-3 mb-3 text-center'>
                    <button type='button' className='fw-bold cta_button bg-warning' onClick={handleAddMoney}>Continue</button>
                  </div>
                </div>
                {showError && <p className="text-danger">{errorMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id='express-checkout-element'></div>

      <div id="checkout-page">
        <ExpressCheckoutElement onConfirm={onConfirm}  options={expressCheckoutOptions}/>
      </div>

      {showAddMoneyModal && (
        <div className="modal-backdrop show"></div>
      )}

    </div>
  )
}

function AccountWithElements() {
  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    appearance: {
      variables: {
        borderRadius: '4px'
      }
    }
  };

    return (               
      <Elements stripe={stripePromise} options={options}>
        <Account />
      </Elements>
    );
  }
  export default AccountWithElements;