import React from "react";
import { BrowserRouter } from "react-router-dom";
import HomeRoutes from './routes';
import './App.css';

function App() {
  console.log('app render')
  return (
      <BrowserRouter>
        <HomeRoutes/>
      </BrowserRouter>
  );
}

export default App;