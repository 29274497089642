import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Account from '../pages/Account';
import PaymentSuccess from '../pages/PaymentSuccess';
import { PAYMENTSUCCESS , TRANSACTIONHISTORY  } from '../helpers/Constant';
import TransactionHistory from '../pages/TransactionHistory';

const HomeRoutes = () => {
    return (
        <Routes>
            <Route path={`/`} element={<Account />} />
            <Route path={`/${PAYMENTSUCCESS}`} element={<PaymentSuccess />} />
            <Route path={`/${TRANSACTIONHISTORY}`} element={<TransactionHistory />} />
        </Routes>
    );
}

export default HomeRoutes;