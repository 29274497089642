export const VERSION = "v1";
export const API_URL = `${process.env.REACT_APP_BASE_PATH}/api/${VERSION}/`;

export const ACCOUNT ='';
export const PAYMENTSUCCESS ='payment-success';
export const TRANSACTIONHISTORY ='transactionhistory';

// api
export const GETPAYMENTDATA ='payment';
export const GETPAYMENTSUCCESS ='payment/paymentsuccess';
export const GETTRANSACTION ='payment/transactionhistory';
export const GETACCOUNT ='account';
export const EXPRESSPAYMENT ='payment/expressCheckoutConfirmation';