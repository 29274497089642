import React, { useEffect, useState } from 'react';
import { getData } from '../api/api';
import moment from 'moment';
import { GETTRANSACTION } from '../helpers/Constant';
import BackButton from '../common/backButton';


function TransactionHistory() {
    const [transaction, setTransaction] = useState([]);

    useEffect(() => {
        async function fetchTransaction() {
            const transaction = await getData(GETTRANSACTION);
            setTransaction(transaction);
        }
        fetchTransaction();
    }, []);
    return (
        <div className='wrapper h-100 overflow-hidden'>
            <BackButton label='Transactions' />
            <div className="transaction_section d-flex flex-column h-100">
                {
                    (transaction.length > 0) ?
                        <>
                            <h6 className="text-white mx-3 mt-4 mb-2 opacity-75">All transactions</h6>
                            <div className="transaction_inner bg-secondary rounded-3 p-3 pb-2 flex-grow-1">
                                {transaction.slice().reverse().map((accountHistory) => (
                                    <div key={accountHistory._id} className="d-flex gap-1 rounded mb-2">
                                        <span className="text-white opacity-50 transaction_detail">{accountHistory.description}</span>
                                        
                                        <span className="text-white opacity-25">{moment(accountHistory.createdAt).format('DD/MM/YYYY')}</span>
                                        <span className={`text-end ms-auto text-capitalize ${accountHistory.status === 'Failed' ? 'failed' : accountHistory.status === 'Pending' ? 'pending' : 'text-success'}`}>{accountHistory.status}</span>
                                    </div>
                                ))}
                            </div>
                        </>
                        :
                        <div className="bg-secondary rounded-top-5 p-3 mt-4 pb-4 d-flex align-items-center justify-content-center flex-grow-1">
                            <h6 className="text-light text-center mx-3 mt-4 mb-2">You haven't made any transactions yet.</h6>
                        </div>
                }
            </div>
        </div>)
}

export default TransactionHistory